import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {API_HOST} from "../index";
import qs from "qs";

class TrailCarousel extends React.Component<any, any> {

    constructor(props: any) {
        super(props)

        this.state = {
            trails: undefined,
        }
    }

    componentDidMount() {
        let query = qs.stringify({
            sort: ['id'],
            fields: ['name', 'latitude', 'longitude'],
            populate: {
                images: {
                    fields: 'url'
                },
                sites: {
                    fields: 'name',
                    populate: ['content']
                },
                Content: '*',
            },
        }, {
            encodeValuesOnly: true,
        });

        axios.get(`${API_HOST}/api/trails?${query}`)
            .then((result) => {
                let data = result.data.data;
                let trails: any[]= [];
                for (let trail in data) {
                    let attributes = data[trail].attributes;
                    let images = attributes.images.data.map((obj: any) => {
                        let image = obj.attributes;
                        return {
                            altText: image.alternateText,
                            caption: image.caption,
                            url: image.url,
                        }
                    });
                    trails.push({
                        name: attributes.name,
                        id: data[trail].id,
                        images: images,
                    });
                }
                this.setState({
                    trails: trails,
                });
            })
            .catch((error) => {
                console.log("There was an error loading the trail carousel");
                window.alert("There was a problem with the connection. Please try again.");
                return undefined;
            });
    }

    render() {
        if (this.state.trails === undefined) {
            return <div></div>
        }

        let width = this.state.trails.length < 2 ? "100%": "85%"

        let items = this.state.trails.map((trail: any, key: number) => {
            let rotation = 45 * key;

            return (
                <div className={"home-img carousel-container"}
                     key={key}
                     style={{
                        backgroundImage: `url(${API_HOST}${trail.images[0].url}`,
                        minWidth: width,
                }}>
                    <Link to={`/trails/${trail.id}`} className={"carousel-label"} style={{
                        transform: `rotate(${rotation}deg)`,
                        backgroundImage: "url(/img_2.png)",
                    }}>
                        <div>
                            <h3 style={{
                                transform: `rotate(-${rotation}deg)`,
                            }}>{trail.name}</h3>
                        </div>
                    </Link>
                </div>
            );
        })

        return (
            <div id="trails" style={{flexDirection: this.props.orientation}}>
                {items}
            </div>
        )
    }
}

export {TrailCarousel}
