

import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";
import {MdArrowBack, MdIosShare} from 'react-icons/md';
import {MdArrowForward} from 'react-icons/md';
import {TrailCarousel} from "./TrailCarousel";

class Home extends React.Component<any, any> {

    scroll(direction: number) {
        let element = document.getElementById('trails')!
        element.scrollTo({
            left: element.scrollLeft + direction * 0.85 * window.innerWidth,
            behavior: 'smooth',
        });
    }

    render() {
        let primary = document.documentElement.style.getPropertyValue('--primary');
        document.documentElement.style.setProperty('--remote-primary', primary);
        return (
            <div className={"Page"}>
                <div style={{flex: 0.3}}/>
                <div id={"title"}>
                    <div>
                        <h1 id={"titleHeading"}>Discover South Perth</h1>
                        <p>Experience the art, culture and history of our City.</p>
                    </div>
                </div>
                <div className={"home-explore"}>
                    <div className={"row"}>
                        <div className={"row home-explore-controls"}>
                            <h4>Explore</h4>
                            <div className={"row"}>
                                <div className={"circle-annotation center"}
                                     style={{margin: "3px"}}
                                     onClick={this.scroll.bind(this, -1)}>
                                    <MdArrowBack/>
                                </div>
                                <div className={"circle-annotation center"}
                                     style={{margin: "3px"}}
                                     onClick={this.scroll.bind(this, 1)}>
                                    <MdArrowForward/>
                                </div>
                            </div>
                        </div>
                        <div className={"row"} id={"view-all"}>
                            <div id={"view-all-button"}>
                                <Link to="/search">
                                    <h5>View all</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"homePageCarousel"}>
                    <TrailCarousel orientation={"row"}/></div>
                </div>
        );
    }
}

export default Home;
