import React from "react";
import '../App.css';
import {Navigate} from "react-router-dom";
import TabBar from "./TabBar";
import routes from "../data/routes";
import {MdKeyboardArrowRight} from "react-icons/md";
import {renderToStaticMarkup} from "react-dom/server";
import axios from "axios";
import {API_HOST} from "../index";
import qs from "qs";

const LINE_WIDTH_DEFAULT = 4;

class TrailMap extends React.Component<any, any> {
    private map: mapkit.Map | undefined;

    constructor(props: any) {
        super(props);

        this.state = {
            showModal: false,
            trails: undefined,
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    async componentDidMount() {
        // this.canvas = document.createElement('canvas')
        // this.canvas.id = 'currentLocationOverride'

        this.map = new mapkit.Map(
            'map',
            {
                region: new mapkit.CoordinateRegion(
                    new mapkit.Coordinate(-31.979, 115.854),
                    new mapkit.CoordinateSpan(0.03, 0.022),
                ),
                showsUserLocation: true,
                showsUserLocationControl: true,
            }
        )

        let query = qs.stringify({
            sort: ['id'],
            fields: ['name', 'latitude', 'longitude', 'color', 'route']
        }, {
            encodeValuesOnly: true,
        });

        await axios.get(`${API_HOST}/api/trails?${query}`)
            .then((result) => {
                let data = result.data.data;
                let trails: any[] = [];
                for (let trail in data) {
                    let attributes = data[trail].attributes;
                    trails.push({
                        id: data[trail].id,
                        ...attributes,
                    });
                }
                console.log(trails);
                this.setState({
                    trails: trails,
                }, () => {
                    this.loadMaps();
                });
            })
            .catch((error) => {
                console.log("There was an error loading the trail carousel");
                console.log(error);
                window.alert("There was a problem connecting to the server");
                return undefined;
            });
    }

    loadMaps() {
        // @ts-ignore
        this.map.cameraZoomRange = new mapkit.CameraZoomRange(1000, 15000)

        let factory = function(coordinate: mapkit.Coordinate, options: mapkit.AnnotationConstructorOptions) {
            let innerHTML = (
                <div className={"trail-annotation"} style={{color: options.data.color}}>
                    <h3>{options.title}</h3>
                    <MdKeyboardArrowRight style={{fontSize: "26px"}}/>
                </div>
            );
            let element = document.createElement("div");
            let staticElement = renderToStaticMarkup(innerHTML);
            element.innerHTML = `${staticElement}`;
            return element
        }

        let calloutDelegate = {
            calloutElementForAnnotation: (annotation: mapkit.Annotation) => {
                let element = document.createElement("div");
                element.className = "hidden-element";
                this.setState({showModal: true, id: annotation.data.id})
                return element;
            }
        }

        if (this.map === undefined) {
            return;
        }
        this.state.trails.forEach((trail: any) => {
            let options = {
                title: trail.name,
                data: {color: trail.color, id: trail.id},
                draggable: false,
                callout: calloutDelegate,
            }
            let annotation = new mapkit.Annotation(
                new mapkit.Coordinate(trail.latitude, trail.longitude),
                factory,
                options);
            if (this.map !== undefined) {
                this.map.addAnnotation(annotation);
            } else {
                console.warn("this.map undefined")
            }

            mapkit.importGeoJSON(trail.route, {

                itemForLineString: (overlay) => {
                    overlay.style = new mapkit.Style({
                        lineWidth: LINE_WIDTH_DEFAULT,
                        strokeColor: trail.color,
                    })
                    return overlay
                },
                geoJSONDidComplete: (overlays) => {
                    // @ts-ignore=  // addItems() doesn't seem to be recognised by ts
                    this.map.addItems(overlays);
                }
            });
        })
    }

    render() {
        let primary = document.documentElement.style.getPropertyValue('--primary');
        document.documentElement.style.setProperty('--remote-primary', primary);
        let theme = document.querySelector("meta[name=theme-color]")!;
        theme.setAttribute("content", "#000000");

        return (
            <>
                <div id={"map"} style={{
                    height: '100%'
                }}/>
                {this.state.showModal && <Navigate to={`/trails/${this.state.id}`}/>}
            </>
        )
    }
}

export default TrailMap
