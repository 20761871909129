import React from 'react';
import '../App.css';
import {MdIosShare} from 'react-icons/md';


class InstallPrompt extends React.Component<any, any> {

     constructor(props: any) {
         super(props);
         let shouldShow = localStorage.getItem('installPromptShown') === 'true' ? 'none': 'flex';
         if(window.matchMedia('(display-mode: standalone)').matches) {
             shouldShow = 'none';
         }
         this.state = {
             shouldShow: shouldShow,
         }
     }

    render() {
        return (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.5)',
                zIndex: 100,
                justifyContent: "center",
                alignItems: "center",
                display: this.state.shouldShow,
            }}
                 onClick={() => {
                     this.setState({shouldShow: 'none'});
                     localStorage.setItem('installPromptShown', 'true');
                 }}>
                <div style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    margin: "1em",
                    textAlign: "center",
                    padding: "30px 0 0 0",
                }}>
                    <div style={{padding: "0 20px 20px 20px"}}>
                        <h4>Install Discover South Perth</h4>
                        <p>This website runs best as an app. To install on your phone, add it to Home Screen</p>
                    </div>
                    <div style={{
                        backgroundColor: "whitesmoke",
                        height: "45px",
                        borderRadius: "10px",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <p> Just tap <span style={{fontSize: "24px"}}><MdIosShare/></span> then "Add to Home Screen"</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstallPrompt;
