import {Link, useNavigate} from "react-router-dom";
import React from "react";
import '../App.css';
import { MdHomeFilled } from "react-icons/md";
import { FaMap } from 'react-icons/fa';
import { CgSearch } from 'react-icons/cg';
import { MdOutlineMoreHoriz } from 'react-icons/md';

const Navigate = (props: any) => {
    const nav = useNavigate();
    nav(props.path);
    return <></>;
}

class TabBar extends React.Component<any, any> {
    icons: Array<React.ReactElement>;

    constructor(props: any) {
        super(props);

        if (this.props.routes.length !== 4) {
            throw new TypeError('Routes array is not the correct length')
        }
        this.icons = [<MdHomeFilled/>, <FaMap/>, <CgSearch/>, <MdOutlineMoreHoriz/>];

        this.state = {
            changePage: false,
            linkTo: '/',
            selected: this.props.routes.indexOf(window.location.pathname),
        }
    }

    render() {
        let links = this.props.routes.map((route: string, key: number) => {
            return <Link
                to={this.props.routes[key]}
                id={this.state.selected === key ? 'selected': ''}
                key={key}
                onClick={() => {
                    this.setState({
                        selected: key,
                        linkTo: this.props.routes[key],
                        changePage: true,
                    })
                }}>
                {this.icons[key]}
            </Link>
        })

        return (
            <footer className={'tab-bar'}>
                <div id={'selected-bar'} style={{
                    left: `calc(${this.state.selected} * (100% / 4)`
                }}/>
                {links}
            </footer>
        )
    }
}


export default TabBar;
