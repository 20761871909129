import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
import '../App.css';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import qs from "qs";
import axios from "axios";
import {API_HOST} from "../index";
import {useModal} from "./SiteMap"; // requires a loader


class Site extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        console.log(this.props.modalContext);
        console.log(this.props.params);
        this.state = {
            content: undefined,
        }
    }

    componentDidMount() {
        let query = qs.stringify({
            sort: ['id'],
            fields: ['name', 'latitude', 'longitude'],
            populate: {
                trail: '*',
                images: '*',
                content: '*',
            },
        }, {
            encodeValuesOnly: true,
        });

        let id = this.props.params.site;

        axios.get(`${API_HOST}/api/sites/${id}?${query}`)
            .then((result) => {
                let data = result.data.data;
                let attributes = data.attributes;
                let content = attributes.content.map((obj: any) => {
                    return {
                        heading: obj.heading,
                        body: obj.body
                    }
                })
                let images = attributes.images.data.map((obj: any) => {
                    let image = obj.attributes;
                    return {
                        altText: image.alternateText,
                        caption: image.caption,
                        url: image.url,
                    }
                });
                this.setState({
                    ...attributes,
                    content: content,
                    images: images,
                    color: attributes.trail.data.attributes.color,
                    id: data.id,
                }, ()  => {
                });
            })
            .catch((error) => {
                console.log("There was an error loading the trail carousel");
                console.log(error);
                window.alert("There was a problem connecting to the server");
                return undefined;
            });
    }


    render() {
        if (this.state.content === undefined) return <div/>
        let paragraphs = this.state.content.map((paragraph: any, key: number) => {
            return (
                <article key={key}>
                    <h3>{paragraph.heading}</h3>
                    <p className={'darken'}>{paragraph.body}</p>
                </article>
            );
        });

        let images = this.state.images.map((image: any, key: number) => {
            return (
                <div key={key}>
                    <img className={"dynamic-image"} src={`${API_HOST}${image.url}`} alt={image.altText} loading={"lazy"}/>
                </div>)
        });

        document.documentElement.style.setProperty('--remote-primary', this.state.color);
        let theme = document.querySelector("meta[name=theme-color]")!;
        theme.setAttribute("content", this.state.color);

        return (
            <div className={"Page modal"}>
                <div className={"scroll-view"}>
                    <Carousel
                        showArrows={true}
                        showThumbs={false}
                        showIndicators={true}
                        showStatus={false}
                        dynamicHeight={false}
                    >
                        {images}
                    </Carousel>
                    <section className="site-copy" style={{color: this.state.color}}>
                        {paragraphs}
                    </section>
                </div>
                <footer style={{backgroundColor: this.state.color, color: "#FFF"}}
                        onClick={() => {
                            this.props.modalContext[1](false);
                            this.props.navigate("/trails/" + this.props.params.id + "/map");
                        }}>
                    <div className={"alt-tab"}>
                        <MdKeyboardArrowLeft style={{fontSize: "20px", padding: "5px"}}/>
                        <h5>Back to Trail</h5>
                    </div>
                </footer>
            </div>
        )
    }
}


function withParamsAndModal(Component: any) {
    return (props: any) => <Component {...props} params={useParams()} navigate={useNavigate()} modalContext={useModal()} />;
}

export default withParamsAndModal(Site);
