import React from "react";
import '../App.css';
import {Outlet, Link, useOutletContext} from "react-router-dom";
import {MdKeyboardArrowLeft} from "react-icons/md";
import qs from "qs";
import axios from "axios";
import {API_HOST} from "../index";
import {MapKit} from "./MapKit";
import withParams from "../withParams";

// const LINE_WIDTH_DEFAULT = 4;

class SiteMap extends React.Component<any, any> {
    map: JSX.Element | undefined = undefined;

    constructor(props: any) {
        super(props);

        this.setShowModal = this.setShowModal.bind(this);
        this.openSite = this.openSite.bind(this);

        this.state = {
            map: undefined,
            showModal: !window.location.pathname.endsWith("map"),
            selected: undefined,
        }
    }

    componentDidMount() {
        let query = qs.stringify({
            sort: ['id'],
            fields: ['name', 'latitude', 'longitude', 'color', 'time', 'length', 'route'],
            populate: {
                images: '*',
                sites: {
                    fields: ['name', 'latitude', 'longitude'],
                    populate: ['content'],
                    sort: ['order', 'id'],
                },
                content: '*',
            },
        }, {
            encodeValuesOnly: true,
        });

        let id = this.props.params.id;

        axios.get(`${API_HOST}/api/trails/${id}?${query}`)
            .then((result) => {
                let data = result.data.data;
                let attributes = data.attributes;
                let content = attributes.content.map((obj: any) => {
                    return {
                        heading: obj.heading,
                        body: obj.body
                    }
                })
                let sites = attributes.sites.data.map((obj: any) => {
                    let site = obj.attributes;
                    return {
                        id: obj.id,
                        name: site.name,
                        latitude: site.latitude,
                        longitude: site.longitude,
                    }
                })
                this.setState({
                    ...attributes,
                    id: data.id,
                    sites: sites,
                    content: content,
                    selected: sites[this.props.params.seq - 1],
                }, () => {
                    this.initialiseMap()
                })
            }).catch((error) => {
                console.log("There was an error loading the trail carousel");
                console.log(error);
                window.alert("There was a problem with the connection!");
                return undefined;
            });
    }

    initialiseMap() {
        this.setState({
            map: new MapKit({
                callback: this.openSite,
                iconType: 'site',
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                annotations: this.state.sites,
                minZoom: 5000,
                maxZoom: 15,
                route: this.state.route,
                ...this.state,
            })
        })
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (window.location.pathname.endsWith("map")
            && this.state.showModal) {
                this.setShowModal(false);
        }
    }

    openSite(data: any) {
        this.setShowModal(true);
        this.setState({selected: data});
        this.props.navigate(`${data.id}/${data.index}`);
    }

    setShowModal(open: boolean) {
        this.setState({showModal: open})
        if (!open) {
            this.state.map.deselectAnnotation();
        }
    }

    render() {
        document.documentElement.style.setProperty('--remote-primary', this.state.color);
        document.documentElement.style.setProperty('--remote-secondary', this.state.color_secondary);
        let theme = document.querySelector("meta[name=theme-color]")!;
        theme.setAttribute("content", this.state.color);

        let selected = this.state.selected;
        if (!selected) {
            selected = this.state
        }

        return (
            <div className={"Page"} style={{color: this.state.color}}>
                <header>
                    <h2>{this.state.showModal
                            ? selected.name
                            : this.state.name}</h2>
                    <div className={"back-circle"}>
                        {this.state.showModal
                            ? <h5>{this.props.params.seq}</h5>
                            : <Link to={"../" + this.props.params.id}>
                                <MdKeyboardArrowLeft style={{fontSize: "28px", color: "white"}}/>
                            </Link>}
                    </div>
                </header>
                <div className={"scroll-view"}>
                    <Outlet context={[this.state.showModal, this.setShowModal]}/>
                    <div id={'map'} style={{display: this.state.showModal ? "none": "block"}}/>
                </div>
            </div>
        )
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default withParams(SiteMap);

export function useModal() {
    return useOutletContext<any>();
}
