import axios from "axios";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter, Outlet, Route, Routes
} from "react-router-dom";
import App from './App';
import Home from './components/Home';
import InstallPrompt from "./components/InstallPrompt";
import More from "./components/More";
import MorePage from './components/MorePage';
import Search from './components/Search';
import Site from "./components/Site";
import SiteMap from "./components/SiteMap";
import Trail from "./components/Trail";
import TrailMap from "./components/TrailMap";
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';





const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

axios.defaults.headers.common['Authorization'] = 'bearer 1ea3744e1373069da925ef8220f9965ca85cf7d8b006f80f9be06d0113e58d990761e28ff5040abec2f79fae5f1bc4068cf8bb180cc55e15bf0982008a432ad3b1fb2d5172748aa92de03c80e302cdad4b1c134f6913c227c75d57a2c1532edd01a61bba5bc299dddf1980c63967fd6a106817af70cd444b4a3ccaed67c4dbfa';
const tokenID = "eyJraWQiOiI0OVVHNkhYNE1UIiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiI1V0ZMNzdCTEFBIiwiaWF0IjoxNzI4MDE2NzU0LCJvcmlnaW4iOiJkaXNjb3Zlci5zb3V0aHBlcnRoLndhLmdvdi5hdSIsImV4cCI6MTc1OTQxMzYwMH0.UZtpjIHpwYqU00tFty1UcWJ_SBQMxNA6eSYp_G1Xj4MnRKdBT7gBm4YkK0Q8msSmZM91YkzjDBbbgineNwhwmg";

const MAX_WIDTH = 1024;
const MAX_HEIGHT = 1080;
const API_HOST = "https://discover.southperth.wa.gov.au/api";
const HOST = "https://discover.southperth.wa.gov.au/";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

class Index extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    async componentDidMount() {
        mapkit.init({
            authorizationCallback: function(done: (arg0: string) => void) {
                done(tokenID);
            }
        });
    }

    render() {
        let viewPortHeight = this.state.width < MAX_WIDTH ? this.state.height : 695;
        let viewPortWidth = this.state.width < MAX_WIDTH ? this.state.width : 340;

        return <div>
            {this.state.width < MAX_WIDTH
                ? <div style={{
                    width: viewPortWidth,
                    height: viewPortHeight
                }}>
                    <InstallPrompt />
                    <Outlet />
                </div>
                : <>
                    <div className="container">
                        <div id={"device"}>
                            <div id="device-container">
                                <div id="topbar" />
                                <div id={"iframe"}>{<Outlet />}</div>
                                <img id="frame" src="/iphone-13-raw.webp" alt={'iPhone 13'} />
                            </div>
                        </div>
                        <div className={"desktop-text"}>
                            <div className={"text-container"}>
                                <h1>Discover South Perth</h1>
                                <h4>To experience the Discover South Perth App at its full potential, please visit
                                    <span onClick={() => {
                                        window.navigator.share({
                                            title: 'Discover South Perth',
                                            text: 'Explore the trails around South Perth',
                                            url: HOST
                                        })
                                            .then(() => console.log('Share was successful.'))
                                            .catch((error) => console.log('Sharing failed', error));
                                    }}>{HOST}</span> on your mobile device and follow the prompts to add it to
                                    your Home Screen.</h4>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    }
}

root.render(
    <BrowserRouter>
        <Routes>
            <Route element={<Index />}>
                <Route path="/" element={<App />}>
                    <Route path="map" element={<TrailMap />} />
                    <Route path="search" element={<Search />} />
                    <Route path="more" element={<More />} />
                    <Route path="more/:id" element={<MorePage />} />
                    <Route index element={<Home />} />
                </Route>
                <Route path="trails">
                    <Route path=":id" element={<Trail />} />
                    <Route path=":id/map" element={<SiteMap />}>
                        <Route path=":site/:seq" element={<Site />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
);

export { API_HOST, HOST };
