import React from 'react';
import './App.css';
import {Outlet, Link, useLocation} from "react-router-dom";
import TabBar from "./components/TabBar";
import {MdKeyboardArrowLeft} from "react-icons/md";

class App extends React.Component<any, any> {
    routes: Array<string> = ['/', '/map', '/search', '/more'];

    render() {
        let primary = document.documentElement.style.getPropertyValue('--primary');
        document.documentElement.style.setProperty('--remote-primary', primary);
        console.log(this.props.location);
        return (
            <div className="App">
                <header>
                    <img id="logo" src="/logo.svg"/>
                    {this.props.location.pathname.startsWith("/more/")
                        ? <div className={"back-circle"}>
                            <Link to={"/more"}>
                                <MdKeyboardArrowLeft style={{fontSize: "28px", color: "white"}}/>
                            </Link>
                        </div>
                        : <div/>
                    }
                </header>
                <div style={{flex: 1, overflow: "scroll"}}>
                    <Outlet/>
                </div>
                <TabBar routes={this.routes}/>
            </div>
        );
    }
}

function withLocation(Component: any) {
    return (props: any) => <Component {...props} location={useLocation()}/>;
}

export default withLocation(App);
