import React from "react";
import {Link, useParams} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
import '../App.css';
import { MdNavigation, MdKeyboardArrowLeft } from 'react-icons/md';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import qs from "qs";
import axios from "axios";
import {API_HOST} from "../index"; // requires a loader
import withParams from "../withParams";

class Trail extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        console.log(this.props);
        console.log(this.props.params);

        this.state = {
            name: undefined,
            images: undefined,
            content: undefined,
        }
    }

    componentDidMount() {
        let query = qs.stringify({
            sort: ['id'],
            fields: ['name', 'latitude', 'longitude', 'color', 'time', 'length', 'color_secondary'],
            populate: {
                images: '*',
                sites: {
                    fields: 'name',
                    populate: ['content']
                },
                content: '*',
            },
        }, {
            encodeValuesOnly: true,
        });

        let id = this.props.params.id;


        axios.get(`${API_HOST}/api/trails/${id}?${query}`)
            .then((result) => {
                let data = result.data.data;
                let attributes = data.attributes;
                console.log(attributes);
                let images = attributes.images.data.map((obj: any) => {
                    let image = obj.attributes;
                    return {
                        altText: image.alternateText,
                        url: image.url,
                    }
                });
                let content = attributes.content.map((obj: any) => {
                    return {
                        heading: obj.heading,
                        body: obj.body
                    }
                })
                console.log(content);
                this.setState({
                    ...attributes,
                    images: images,
                    content: content,
                })
            })
            .catch((error) => {
                console.log("THere was an error loading the trail carousel");
                console.log(error);
                window.alert("There was a problem connecting to the server");
                return undefined;
            });
    }

    adjustColor(color: string, shift: number) {
        let r = parseInt(color.substring(1, 3), 16);
        let g = parseInt(color.substring(3, 5), 16);
        let b = parseInt(color.substring(5, 7), 16);

        r = Math.floor(Math.min((1 - shift) * r + shift * 255, 255));
        g = Math.floor(Math.min((1 - shift) * g + shift * 255, 255));
        b = Math.floor(Math.min((1 - shift) * b + shift * 255, 255));

        console.log(r);
        console.log(g);
        console.log(b);

        let output = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
        console.log(output);
        return output;
    }

    render() {
        if (this.state.name === undefined)
            return <div/>;

        console.log(this.state.color_secondary);
        document.documentElement.style.setProperty('--remote-primary', this.state.color);
        let theme = document.querySelector("meta[name=theme-color]")!;
        theme.setAttribute("content", this.state.color);
        document.documentElement.style.setProperty('--remote-secondary', this.state.color_secondary);
        document.documentElement.style.setProperty('--remote-tertiary', this.adjustColor(this.state.color_secondary, 0.9));

        let paragraphs = this.state.content.map((copy: any, key: number) => {
            console.log(copy);
            return (
                <article key={key}>
                    <h3>{copy.heading}</h3>
                    <p className={'darken'}>{copy.body}</p>
                </article>
            );
        });

        let images = this.state.images.map((image: any, key: number) => {
            console.log(image);
            return (
                <div key={key}>
                    <img className={"dynamic-image"} src={`${API_HOST}${image.url}`} alt={image.altText}/>
                </div>)
        });

        return (
            <div className={"Page"} style={{
                color: this.state.color}}>
                <header style={{color: this.state.color}}>
                    <h2>{this.state.name}</h2>
                    <Link to={'/map'}>
                        <div className={"back-circle"} style={{backgroundColor: this.state.color}}>
                            <MdKeyboardArrowLeft style={{fontSize: "28px"}}/>
                        </div>
                    </Link>
                </header>
                <div className={"scroll-view"}>
                    <Carousel
                        showArrows={false}
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        dynamicHeight={false}
                    >
                        {images}
                    </Carousel>
                    <div className={"fact-box"}>
                        <div className={"fact"}>
                            <h6>Sites</h6>
                            <h5>{this.state.sites.data.length}</h5>
                        </div>
                        <div className={"fact"}>
                            <h6>Length</h6>
                            <h5>{this.state.length}km</h5>
                        </div>
                        <div className={"fact"}>
                            <h6>Time</h6>
                            <h5>{this.state.time}hrs</h5>
                        </div>
                    </div>
                    <section style={{color: this.state.color}}>
                        {paragraphs}
                    </section>
                </div>
                <footer style={{backgroundColor: this.state.color, color: "#FFF" }}>
                   <Link className={"alt-tab"} to={"map"}>
                       <MdNavigation style={{fontSize: "20px", padding: "5px"}}/>
                       <h5>Start Trail</h5>
                   </Link>
                </footer>
            </div>
        )
    }
}

export default withParams(Trail);
