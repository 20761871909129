import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";
import {HOST} from "../index";

class More extends React.Component<any, any> {

    shareData = {
        title: 'Discover South Perth',
        text: 'Explore the trails around South Perth',
        url: HOST,
    }

    constructor(props: any) {
        super(props);

        this.state = {
            share: false,
        }
        this.handleShare = this.handleShare.bind(this);
    }

    handleShare() {
        console.log("Handle share button")
        this.setState({share: true})
    }

    render() {

        let links = [
            {
                name: "About the App",
                linkTo: "about"
            },
            {
                name: "Give us feedback",
                linkTo: "feedback"
            },
            {
                name: "Share this App",
                linkTo: "share"
            },
            {
                name: "Follow us",
                linkTo: "follow"
            },
            {
                name: "Legal",
                linkTo: "legal"
            },
        ]

        let buttons = links.map(
            (link, index) => {
                let action = (
                    <Link to={link.linkTo}>
                        <h4>{link.name}</h4>
                    </Link>
                )
                if (link.linkTo === "share") {
                    action = (
                        <div onClick={this.handleShare}>
                            <h4>{link.name}</h4>
                        </div>
                    )
                }

                return (
                    <div className="more-button" key={index}>
                        {action}
                    </div>
                )
            });

        if (this.state.share) {
            window.navigator.share(this.shareData)
                .then(() => console.log('Share was successful.'))
                .catch((error) => console.log('Sharing failed', error));
        }

        return (
            <div className={"more"}>
                <div className={"plain-text-box"}>
                    <h1>More</h1>
                    <h6>Support</h6>
                </div>
                {buttons}
            </div>
        );
    }
}

export default More;

