import React from 'react';
import '../App.css';
import {MdFacebook} from "react-icons/md";
import {AiOutlineInstagram} from "react-icons/ai";
import qs from "qs";
import {API_HOST, HOST} from "../index";
import axios from 'axios';
import withParams from '../withParams';

class MorePage extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            content: undefined,
            type: this.props.params.id,
            width: window.innerWidth,
            height: window.innerHeight,
            name: "",
            email: "",
            feedback: "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (["legal", "about"].includes(this.state.type)) {
            let query = qs.stringify({
                populate: '*'
            }, {
                encodeValuesOnly: true,
            });

            axios.get(`${API_HOST}/api/${this.state.type}?${query}`)
                .then((response: any) => {

                    response = response.data.data;
                    console.log(response);
                    this.setState({
                        content: response.attributes.content,
                    })
                })
                .catch();
        }
    }

    handleInputChange(event: { target: any; }) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();
        console.log(`${this.state.name}, ${this.state.email}, ${this.state.feedback}`)

        let headers = {"Content-Type": "application/json"}
        axios.post(`${HOST}/feedback`, {
            name: this.state.name,
            email: this.state.email,
            message: this.state.feedback
        }, {
            headers: headers
        }).then(() => {
            alert("Submitted Successfully");
        }).catch((error) => {
            alert("There was an error submitting your feedback.");
            console.log(error);
        })
    }

    render() {

        let body;
        if (this.state.type === "feedback") {
            body = <div>
                <form>
                    <label><h6>Name</h6></label>
                    <input
                        name={"name"}
                        placeholder={"Your name"}
                        value={this.state.name}
                        onChange={this.handleInputChange}/>
                    <label><h6>Email</h6></label>
                    <input
                        name={"email"}
                        placeholder={"Your email address"}
                        type={'email'}
                        value={this.state.email}
                        onChange={this.handleInputChange}/>
                    <label><h6>Feedback</h6></label>
                    <textarea
                        name={"feedback"}
                        placeholder={"Enter your feedback"}
                        rows={10}
                        value={this.state.feedback}
                        onChange={this.handleInputChange}/>
                    <button onClick={this.handleSubmit}><h5>Submit feedback</h5></button>
                </form>
            </div>
        } else if (this.state.type === "follow") {
            body = (
                <div>
                    <h6 className={"plain-text-box heading"}>@discoversouthperth</h6>
                    <a href="https://www.facebook.com/CityofSouthPerth"
                       className={"more-button"}>
                        <MdFacebook className={"more-icon"}/>
                        <h4> Facebook </h4>
                    </a>
                    <a href="https://www.instagram.com/cityofsouthperth/"
                       className={"more-button"}>
                        <AiOutlineInstagram className={"more-icon"}/>
                        <h4> Instagram </h4>
                    </a>
                </div>
            )
        } else {
            if (this.state.content !== undefined) {
                let paragraphs = this.state.content.map((content: any, key: number) => {
                    return (
                        <article key={key}>
                            <h6 className={"heading"}>{content.heading}</h6>
                            <p>{content.body}</p>
                        </article>
                    );
                });
                body = (
                    <section className={"more"}>
                        {paragraphs}
                    </section>
                )
            }
        }

        return (
            <div className={"more scroll-view"} style={{backgroundColor: 'var(--primary)'}}>
                <div className={"plain-text-box"}>
                    <h1>{this.props.params.id}</h1>
                </div>
                {body}
            </div>
        );
    }
}

export default withParams(MorePage);

