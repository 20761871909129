import React from "react";
import { Link, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import '../App.css';
import { MdNavigation, MdKeyboardArrowLeft } from 'react-icons/md';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TabBar from "./TabBar"; // requires a loader
import { TrailCarousel } from "./TrailCarousel";

class Search extends React.Component<any, any> {

    render() {
        return (
            <div className={"scroll-view search"}>
                <div className={"plain-text-box"}>
                    <h1>Search</h1>
                </div>
                <div style={{ margin: "0 1em", marginBottom: "4em" }}>
                    <TrailCarousel orientation={"column"} />
                </div>
            </div>
        )
    }
}

export default Search;
