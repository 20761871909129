import React from "react";
import '../App.css';
import {MdKeyboardArrowRight} from "react-icons/md";
import {renderToStaticMarkup} from "react-dom/server";

const LINE_WIDTH_DEFAULT = 4;

class MapKit {
    private readonly map: mapkit.Map | undefined;
    private props: any;

    constructor(props: any) {
        this.props = props;
        // console.log(this.props);
        this.map = new mapkit.Map(
            'map',
            {
                region: new mapkit.CoordinateRegion(
                    new mapkit.Coordinate(this.props.latitude, this.props.longitude),
                    new mapkit.CoordinateSpan(0.025, 0.015),
                ),
                showsUserLocation: true,
                showsUserLocationControl: true,
            }
        )
        // @ts-ignore
        this.map.cameraZoomRange = new mapkit.CameraZoomRange(this.props.maxZoom, this.props.minZoom)
        let factory: (arg0: mapkit.Coordinate, arg1: mapkit.AnnotationConstructorOptions) => HTMLElement;

        switch (this.props.iconType) {
            case 'site':
                factory = function (coordinate: mapkit.Coordinate, options: mapkit.AnnotationConstructorOptions) {
                    let annotation = document.createElement("div")
                    annotation.textContent = options.data.index as string;
                    annotation.className = "trail-site";
                    return annotation;
                }
                break;
            default:
                factory = function (coordinate: mapkit.Coordinate, options: mapkit.AnnotationConstructorOptions) {
                    let innerHTML = (
                        <div className={"trail-annotation"} style={{color: options.data.color}}>
                            <h3>{options.title}</h3>
                            <MdKeyboardArrowRight style={{fontSize: "26px"}}/>
                        </div>
                    );
                    let element = document.createElement("div");
                    let staticElement = renderToStaticMarkup(innerHTML);
                    element.innerHTML = `${staticElement}`;
                    return element
                }
        }

        let calloutDelegate = {
            calloutElementForAnnotation: (annotation: mapkit.Annotation) => {
                let element = document.createElement("div");
                element.className = "hidden-element";
                this.props.callback(annotation.data);
                return element;
            }
        }

        if (this.map === undefined) {
            return;
        }

        this.props.annotations.forEach((annotation: any, key: number) => {
            let options = {
                title: annotation.name,
                data: {
                    index: key + 1,
                    ...annotation
                },
                draggable: false,
                callout: calloutDelegate,
                anchorOffset: new DOMPoint(0, -14)
            }
            let annotationElement = new mapkit.Annotation(
                new mapkit.Coordinate(annotation.latitude, annotation.longitude),
                factory,
                options
            );
            if (this.map !== undefined) {
                this.map.addAnnotation(annotationElement);
            } else {
                console.warn("this.map undefined")
            }

            if (this.props.iconType === "trail") {
                this.addGeoJSON(annotation.route, annotation.color);
            }
        })
        if (this.props.iconType === "site") {
            this.addGeoJSON(this.props.route, this.props.color)
        }
        this.map.addEventListener('region-change-start', this.handleEvent, this);
        this.map.addEventListener('region-change-end', this.handleEvent, this);
    }

    handleEvent(event: any) {
        console.log(event);
    }

    deselectAnnotation() {
        this.map!.selectedAnnotation = null;
    }

    addGeoJSON(route: any, color: string) {
        mapkit.importGeoJSON(route, {
            itemForLineString: (overlay) => {
                overlay.style = new mapkit.Style({
                    lineWidth: LINE_WIDTH_DEFAULT,
                    strokeColor: color,
                })
                return overlay
            },
            geoJSONDidComplete: (overlays) => {
                // @ts-ignore=  // addItems() doesn't seem to be recognised by ts
                this.map.addItems(overlays);
            }
        });
    }
}

export {MapKit};
